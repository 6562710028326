<template>
<div  v-show="!bgisloaded">
  <el-row type="flex" justify="center">
  <div class="loadingdiv" v-show="show_loading">
        <img src="/img/bg.png" style="display:none" />
        <img src="/img/ds-avatar.png" style="display:none" />
        <img src="./assets/logo.svg"  />
        <el-progress :text-inside="true" :stroke-width="22" :percentage="loadper" status="warning"></el-progress>
  </div>
  </el-row>
</div>
<div id="mobile" v-show="bgisloaded" ref="indexbg">
  <el-container>
    <el-header height="0.66rem">
      <el-affix >
        <div class="header-bg-color" :class="{'darkbgcolor':darkbgcolor}">
              <el-row  type="flex" justify="space-between">
                <el-col :span="12">
                  <div class="logo">
                    <img src="./assets/logo.svg" />
                    <div class="slogan">
                      让人少走弯路的创业课
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                    <div class="nav-title" @click="nav_close=!nav_close"><img src="./assets/list.svg"></div>
                    <div :class="{'nav-content':true,'close':nav_close}">
                        <ul>
                            <li  :class="{'active':activeIndex=='home'}" @click="tabClick('home');nav_close=true">首页</li>
                            <li  :class="{'active':activeIndex=='ke'}" @click="tabClick('ke');nav_close=true">简约课程</li>
                            <li  :class="{'active':activeIndex=='daoshi'}"   @click="tabClick('daoshi');nav_close=true">导师介绍</li>
                            <li  :class="{'active':activeIndex=='jianfei'}"  @click="tabClick('jianfei');nav_close=true">简粉风采</li>
                            <li  :class="{'active':activeIndex=='contact'}" @click="tabClick('contact');nav_close=true">联系我们</li>
                        </ul>
                        <div class="clear"></div>
                    </div>
                </el-col>
              </el-row>
        </div>
      </el-affix>
    </el-header>
    <el-main>
        <div class="content top-b">
            <img class="wanyi" src="./assets/wanyi.svg" />
            <img class="year20" src="./assets/20year.svg" />
            <img class="player pointer" @click="playVideo('about')" src="./assets/player@2x.png" />
            <div class="player-title">点击播放视频了解简约商业思维</div>
        </div>
        <div class="content kebg">
            <h2 ref="ke">线上课程：认知商业商学课系列</h2>
            <ul class="list">
            <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第一课：商业的本质－产品</li>
            <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第二课：商业的本质－效率与成本</li>
            <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第三课：商业的本质－传播</li>
            <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第四课：商业的本质－三项能力</li>
            <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第五课：企业的本质－战略、战术、战斗</li>
            <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第六课：企业的本质－战略与四项能力</li>
            <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第七课：企业的本质－战术与战斗</li>
            <li>...</li>
            </ul>
            <el-row class="btn-row">
            <el-button type="warning" @click="showQr('ke')">立即学习</el-button><span>共20节课程</span>
            </el-row>
        </div><!--/kebg-->
        <div class="xiliebg">
        <div class="content">
        <el-row type="flex" justify="space-between">
            <div class="xiliebox pointer" @click="showQr('contact')">
            <el-image
            style="width: 1.65rem; height: 1.26rem"
            :src="require('./assets/hz.png')"
            fit="cover"></el-image>
            <h3>线下公共课</h3>
            <div class="info">徐井宏老师线下与学员见面亲授，面向全国招募学员，不定期举办</div>
            </div><!--/xiliebox-->

            <div class="xiliebox pointer" @click="showQr('contact')">
            <el-image
            style="width: 1.65rem; height: 1.26rem"
            :src="require('./assets/sdh.png')"
            fit="cover"></el-image>
            <h3>简约私董会</h3>
            <div class="info">群体智慧聚焦为一个企业案主思考破局点,帮助企业找到本质问题，找到解决方案</div>
            </div><!--/xiliebox-->

            <div class="xiliebox pointer" @click="showQr('contact')">
            <el-image
            style="width: 1.65rem; height: 1.26rem"
            :src="require('./assets/xly.png')"
            fit="cover"></el-image>
            <h3>简约训练营</h3>
            <div class="info">理论加深，配合工具应用落地，小组互动深度社交，真正掌握知识的运用</div>
            </div><!--/xiliebox-->

            <div class="xiliebox pointer" @click="showQr('contact')">
            <el-image
            style="width: 1.65rem; height: 1.26rem"
            :src="require('./assets/manhe.png')"
            fit="cover"></el-image>
            <h3>简约盲盒系列</h3>
            <div class="info">工作坊+直播路演，企业家素养、商业计划书、商业模式创新三选一</div>
            </div><!--/xiliebox-->
        </el-row>
        </div><!--/content-->
        </div><!--/xiliebg-->
        <div class="content daoshi">
            <h2 ref="daoshi">导师团队介绍</h2>
            <div class="bigds">
                <div class="ds">
                    <div class="avatar"></div>
                    <h3>徐井宏</h3>
                </div>
                <div class="info" :class="{'open':bigds_open}">
        &nbsp;&nbsp;&nbsp;&nbsp;徐井宏，简约商业思维创建者，中关村龙门投资董事长，亚杰商会（AAMA）荣誉会长，中国企业家俱乐部（CEC）副理事长，清华企业家协会（TEEC）监事长等。曾担任亚太经合组织（APEC）中国工商理事会副主席、世界经济（达沃斯）论坛主席团（COC）成员等。<br /><br />
        &nbsp;&nbsp;&nbsp;&nbsp;清华科技园的主要创建者和启迪控股的主要创始人，曾任清华控股董事长，期间带领清华控股跃升为2018年中国企业500强第137位，研发强度在中国企业500强中位居前三，旗下包括紫光、同方等科技集团，直接和间接控股10余家上市公司。所领导的投资机构投资有展讯通信、中文在线、兆易创新、海兰信、盛景网联等行业龙头企业。<br /><br />
        &nbsp;&nbsp;&nbsp;&nbsp;其百亿规模的龙门基金，已投资奇安信、叮当快药等行业龙头企业。其担任会长的亚杰商会所发起的“摇篮计划”支持了包括美团、完美世界、猎聘网、流利说、拼多多创始人等数百名创业者。并出版有《转型》、《聚合》、《共赢》、《重构》等著作。<br />
                </div>
                <div class="more" @click="bigds_open=!bigds_open">{{bigds_open?'收起':'展开更多介绍'}}</div>
            </div><!--/bigds-->
            <div class="dslist">
                <h2>共创导师团队</h2>
                <el-row>
                    <el-col :span="8">
                        <div class="avatar ztb"></div>
                        <h3>朱天博</h3>
                        <div class="info">简约商业思维共创人<br/>中甬投创始合伙人<br />岂止书院、问榜发起人</div>
                    </el-col>
                    <el-col :span="8">
                        <div class="avatar tl"></div>
                        <h3>檀林</h3>
                        <div class="info">
                            简约商业思维共创人<br />
                            海尔海创汇合伙人<br />前微软加速器CEO
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="avatar zzm"></div>
                        <h3>张正明</h3>
                        <div class="info">
                            简约商业思维共创人<br />
                            海尔HOPE创新大使<br />
                            创新顾问
                        </div>
                    </el-col>

                    <el-col :span="8">
                        <div class="avatar jy"></div>
                        <h3>简一</h3>
                        <div class="info">
                            简约商业思维共创人<br />
                            岂止书院发起人<br />青林文化创始人
                        </div>
                    </el-col>

                    <el-col :span="8">
                        <div class="avatar lf"></div>
                        <h3>罗飞</h3>
                        <div class="info">
                            简约商业思维共创人<br />
                            岂止书院发起人<br />问榜联合创始人
                        </div>
                    </el-col>

                    <el-col :span="8">
                        <div class="avatar hzt"></div>
                        <h3>何战涛</h3>
                        <div class="info">
                            简约商业思维共创人<br />
                            东方快车联合创始人<br />传神语联联合创始人
                        </div>
                    </el-col>

                    <el-col :span="8">
                        <div class="avatar zxp"></div>
                        <h3>周学平</h3>
                        <div class="info">
                            简约共创教练<br />
                            北斗领航创始人<br />人才辅导专家
                        </div>
                    </el-col>

                    <el-col :span="8">
                        <div class="avatar wq"></div>
                        <h3>吴勤</h3>
                        <div class="info">
                             简约共创教练<br />
                             组织效能提升顾问<br />高管教
                        </div>
                    </el-col>


                    <el-col :span="8">
                        <div class="avatar yym"></div>
                        <h3>杨越铭</h3>
                        <div class="info">
                            简约共创编导<br />
                            资深节目主编
                        </div>
                    </el-col>

                </el-row>
            </div><!--dslist-->
        <div class="mt">
            <h2>媒体报道</h2>
            <div class="mt-content">
            <el-image
                style="width: 100%;"
                :src="require('./assets/mt-zcdsh.png')"
                fit="cover" @click="playqqVideo('zcdsh')" ></el-image>
                <p  @click="playqqVideo('zcdsh')">总裁读书会：徐井宏老师分享《当下的力量》</p>
            <div class="space"></div>
            <el-image
                style="width: 100%;"
                :src="require('./assets/mt-cxsj.png')"
                fit="cover"  @click="playqqVideo('cxsj')"></el-image>
                <p  @click="playqqVideo('cxsj')">财新视听采访徐井宏老师</p>
            </div>
        </div>
        </div><!--/daoshi-->
        <div class="fengcaibg">
        <div class="content">
            <h2 ref="jianfei">简粉风采</h2>
            <div class="jfbox" :class="{'open':jianfen_open}">
            <el-row type="flex" justify="space-between">
                <el-image v-for="item in jianfeiData" :key="item.picUrl" :src="item.picUrl" class="pointer" @click="playVideo(item)" fit="cover" style="width:1.55rem;height:1.55rem;margin-bottom:0.1rem;"></el-image>
            </el-row>
            </div>
            <div class="more" @click="jianfen_open=!jianfen_open">{{jianfen_open?'收起':'展开更多'}}</div>
        </div>
        </div><!--/fengcaibg-->
        <div class="contact">
            <div class="content">
                <h2 ref="contact">联系我们</h2>
                <el-row>
                <el-col :span="12">
                <el-image
                    style="width: 100%"
                    :src="require('./assets/qr/new_mp.jpg')"
                    fit="cover"></el-image>
                    <p>关注简约商业思维公众号</p>
                </el-col>

                <el-col :span="12">
                <el-image
                    style="width:100%"
                    :src="require('./assets/qr/new_qy_qun.jpg')"
                    fit="cover"></el-image>
                    <p>进入简约商业思维社群</p>
                </el-col>
                <el-col :span="12">
                <el-image
                    style="width:100%"
                    :src="require('./assets/qr/jianyue.png')"
                    fit="cover"></el-image>
                    <p>关注简约商业思维视频号</p>
                </el-col>
                <el-col :span="12">
                <el-image
                    style="width:100%"
                    :src="require('./assets/qr/xu.png')"
                    fit="cover"></el-image>
                    <p>关注徐井宏视频号</p>
                </el-col>
                 <el-col :span="12">
                <el-image
                    style="width:100%"
                    :src="require('./assets/qr/kefu.jpg')"
                    fit="cover"></el-image>
                    <p>添加客服顾问微信</p>
                </el-col>
                </el-row>
            </div>
        </div><!--/contact-->
    </el-main>
    <el-footer height="2rem">
        <div class="footer-line"></div>
        <div class="content">
        <p class="footer-p">
        <!--
        公司名称：
        -->
        北京简约思维科技有限公司 
        <!--
         <br /> 
        公司地址：北京市海淀区中关村大街XXX  <br /> 
        联系电话：40010000
        -->
        </p>
        <p class="footer-p copyright">
        Copyright 2020-{{(new Date()).getFullYear()}} © xminithink.com All rights reserved. <br /><a href="https://beian.miit.gov.cn/" style="color:#999;" target="_blank">京ICP备2022007505号</a> 
        </p>
        </div>
    </el-footer>
  </el-container>
<el-dialog
  v-model="videoDialog"
  :title="videoTitle"
  width="95%"
  destroy-on-close
  custom-class="video-dialog"
  >
<video style="width:100%;height:100%" autoplay controls controlsList="nodownload">
  <source :src="videoUrl" type="video/mp4">
Your browser does not support the video tag.
</video>
</el-dialog>



<el-dialog
  v-model="qqVideoDialog"
  :title="qqVideoTitle"
  width="95%"
  destroy-on-close
  custom-class="video-dialog qqvideo"
  >
  <iframe style="width:100%;height:2.1rem;" frameborder="0" :src="qqVideoUrl" allowFullScreen="true"></iframe>
</el-dialog>


<el-dialog
  v-model="qrDialog"
  :title="qrTitle"
  width="80%"
  destroy-on-close
  >
  <div class="qr-content">
  <img :src="qrUrl" style="width:90%" />
  </div>
</el-dialog>

</div>
</template>
<script>
import MixinData from './Mixin';
export default {
  name: 'Mobile',
  mixins:[MixinData],
  data(){
      return {
          nav_close:true,
          bigds_open:false,
          jianfen_open:false
      }
  },
  mounted(){
      this.setRem();
      window.onresize=()=>{
          this.setRem();
      };
  },
  methods:{
    setRem() {
       document.documentElement.style.fontSize = document.documentElement.clientWidth / 3.6 + 'px';
    }
  }
}
</script>
<style scoped>
.pointer{
  cursor: pointer;
}
.content{
  margin: 0 0.1rem;
}

.loadingdiv{
  width: 80%;
  margin-top:1rem;
  height:3rem;
  overflow: hidden;
}



.loadingdiv img{
  width:100%;
  margin-bottom:0.1rem;
}

#mobile{
/*
  background:url(/img/bg.png) center top;
*/
  background: #003483  center top;
  background-repeat: no-repeat;
  background-size: cover;
  height:2.7rem;
  font-size:0.14rem;
}
/deep/ .el-header{
    padding:0;
}
/deep/ .el-main{
    padding:0;
}
.top-bg{
  height: 5.44rem;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.main{
  padding:0!important;
  margin: 0!important;
}

.header-bg-color{
  height: 0.45rem;
  background: rgba(0,0,0, 0.5)!important;
  transition: 0.5s;
}

.header-bg-color.darkbgcolor{
  background: rgba(0,0,0, 0.8)!important;
}

.logo{
  text-align: center;
  background: rgba(0, 0,0, 0.5);
  padding:0.1rem 0.1rem 0.05rem;
  color:#76aad9;
  border-radius: 0.08rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#mobile .logo{
  width:1.19rem;
}
.slogan{
  padding-top: 0.1rem;
  font-size: 0.1rem;
}
#mobile .logo img{
  width: 1.2rem;
  display: block;
}

.nav-title{
    color:#fff;
    text-align: right;
    padding-right:0.1rem;
    padding-top:0.14rem;
}

.nav-title img{
    width:0.2rem;
    height:0.2rem;
}

.nav-content{
    color:#fff;
    overflow: hidden;
    max-height: 3rem;
    transition: max-height 0.5s ease-out;
}
.nav-content ul{
    float: right;
    list-style: none;
    padding-inline-start:0;
    background: rgba(0,0,0, 0.5)!important;
    padding:0.1rem;
    position: relative;
    top:-0.08rem;
}

.nav-content.close{
    max-height:0;
    transition: max-height 0.05s ease-in;
}
.nav-content ul li{
    margin-bottom:0.08rem;
}
.nav-content ul li.active{
    color:#e3a018!important;
}
.clear{
    clear: both;
}
.top-b{
    text-align: center;
}
.wanyi{
    display: block;
    width: 3.2rem;
    margin: 0.2rem auto 0.05rem auto;
}

.year20{
    display: block;
    width: 2.5rem;
    margin:0.05rem auto;
}
.player{
    display: block;
    width:0.6rem;
    margin:0 auto;
}
.player-title{
    font-size:0.1rem;
}

/deep/ .el-dialog__header{
    padding:0.1rem;
    padding-bottom: 0;
}

/deep/ .el-dialog__header .el-dialog__title{
    font-size:0.14rem;
    line-height: 0.24rem;
}

/deep/ .el-dialog__header .el-dialog__headerbtn{
    font-size:0.16rem;
    top:0.12rem;
    right:0.12rem;
}


/deep/ .el-dialog__body{
    padding:0.1rem 0.2rem 0.2rem;
    font-size:0.14rem;
}

.top-b{
    margin-bottom:0.2rem;
}

h2{
  font-size:0.14rem;
  color:#101010;
  border-left:#e3a018 0.05rem solid;
  padding-left: 0.05rem;
  margin-left:0.1rem;
}
.kebg{
    background: #fff;
    border-top-right-radius: 0.08rem;
    border-top-left-radius: 0.08rem;
    padding-top:0.03rem;
}
.list{
    list-style: none;
    padding-inline-start:0.08rem;
    line-height: 180%;
}

.list li{
    color:#404040;
}

.list li:last-child{
    padding-left:0.2rem;
}

.list li i{
    color:#9e9e9e;
    margin-right:0.04rem;
}
.btn-row{
    padding-left: 0.15rem;
}
.btn-row span{
    color:#989898;
    margin-top:0.08rem;
    margin-left:0.2rem;
    font-size:0.14rem;
}
/deep/ .el-button{
    min-height: 0.3rem;
    font-size:0.13rem;
    padding:0.1rem 0.18rem;
}
.qr-content{
    text-align: center;
}
.xiliebg{
    background: #edf7ff;
    padding:0.3rem 0;
    margin-top:0.3rem;
}
.xiliebg .xiliebox{
    background: #fff;
    width: 1.65rem;
    border-bottom: 0.02rem solid #e8cb8b;
    margin-bottom: 0.08rem;
}
.xiliebg h3{
    font-size:0.14rem;
    margin-block-start:0;
    margin-block-end:0;
    color:#404040;
    margin-top:0.08rem;
    margin-bottom:0.08rem;
    padding-left:0.1rem;
}

.xiliebg .info{
    color:#989898;
    font-size:0.12rem;
    padding:0 0.1rem 0.15rem 0.1rem
}

.daoshi{
    margin-top: 0.3rem;
}

.daoshi .bigds .ds{
    text-align: center;
    padding-top:0.2rem;
}

.daoshi .bigds .ds h3{
    font-size: 0.16rem;
}

.daoshi .bigds  .info{
    color:#404040;
    font-size:0.12rem;
    max-height:1rem;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
}

.daoshi .bigds  .info.open{
    transition: max-height 0.25s ease-in;
    max-height:3.5rem;
}

.daoshi .bigds .more{
    text-align: center;
}
.ds-avatar-size{
    width: 1rem;
    height: 1rem;
    display: block;
    margin:0 auto;
}

.daoshi .dslist h2{
    margin-bottom:0.2rem;
}

.daoshi .dslist h3{
    font-size:0.14rem;
    margin-block-start:0;
    margin-block-end:0;
    padding-top:0.08rem;
    text-align: center;
}

.daoshi .dslist .info{
    font-size:0.09rem;
    text-align: center;
    color:#404040;
    margin-bottom:0.15rem;
    margin-top:0.03rem;
}
.mt .mt-content{
    padding:0.1rem;
}

.mt .mt-content p{
    color:#404040;
    margin-block-start:0;
    margin-block-end:0;
    margin-bottom:0.2rem;
}

.fengcaibg{
  background: url('./assets/jianfeibg@2x.png');
  background-size:cover;
  padding:0.1rem 0 0.3rem;
}

.fengcaibg h2{
    color:#fff;
}

.fengcaibg .jfbox{
    padding:0.1rem;
    max-height: 3.2rem;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
}

.fengcaibg .jfbox.open{
    max-height: 25rem;
    transition: max-height 0.25s ease-in;
}

.fengcaibg .more{
    color:#fff;
    text-align: center;
}

.contact{
    padding-top:0.3rem;
    background: #f9f9f9;
}

.contact p{
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: center;
    font-size:0.12rem;
    color:#888888;
    margin-bottom:0.15rem;
}

/deep/ .el-footer{
    padding:0;
    background: #f9f9f9;
}

.footer-line{
  height: 0;
  border-top:1px solid #dfdfdf;
  border-bottom:1px solid #ffffff;
  margin-bottom: 0.3rem;
  margin-top:0.3rem;
}

.footer-p{
    margin-block-end: 0;
    margin-block-start: 0;
    /*
    margin-bottom:0.2rem;
    */
    line-height: 180%;
    text-align: center;
}

.copyright{
    color:#999999;
    font-size:0.1rem;
    text-align: center;
}

.avatar{
    background: url('/img/ds-avatar.png') no-repeat center top;
    background-size:1rem auto;
    width: 1rem;
    height:1.05rem;
    margin:0 auto;
}

.avatar.ztb{
    background-position-y: -1.06rem;
}

.avatar.zzm{
    background-position-y: -2.12rem;
}

.avatar.tl{
    background-position-y: -3.2rem;
}

.avatar.jy{
    background-position-y: -4.3rem;
}

.avatar.lf{
    background-position-y: -5.39rem;
}

.avatar.hzt{
    background-position-y: -6.49rem;
}

.avatar.zxp{
    background-position-y: -7.59rem;
}

.avatar.wq{
    background-position-y: -8.7rem;
}
.avatar.yym{
    background-position-y: -9.79rem;
}



</style>