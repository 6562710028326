<template>
<div  v-show="!bgisloaded">
  <el-row type="flex" justify="center">
  <div class="loadingdiv" v-show="show_loading">
    <img src="/img/bg.png" style="display:none" />
    <img src="/img/ds-avatar.png" style="display:none" />
        <img src="./assets/logo.svg"  />
        <el-progress :text-inside="true" :stroke-width="22" :percentage="loadper" status="warning"></el-progress>
  </div>
  </el-row>
</div>
<div id="index" v-show="bgisloaded" ref="indexbg">
<el-container>
 <el-header height="56px">
<el-affix >
<div class="header-bg-color" :class="{'darkbgcolor':darkbgcolor}">
<div class="content">
  <el-row  type="flex" justify="space-between">
    <el-col :span="12">
      <div class="logo">
        <img src="./assets/logo.svg" />
        <div class="slogan">
          让人少走弯路的创业课
        </div>
      </div>
    </el-col>
    <el-col :span="12">
      <el-menu :default-active="activeIndex" @select="tabClick"  mode="horizontal">
        <el-menu-item index="home">
          首页
         <el-divider direction="vertical"></el-divider>
        </el-menu-item>
        <el-menu-item index="ke">
          简约课程
          <el-divider direction="vertical"></el-divider>
        </el-menu-item>
        <el-menu-item index="daoshi">
          导师介绍
          <el-divider direction="vertical"></el-divider>
        </el-menu-item>
        <el-menu-item index="jianfei">
          简粉风采
          <el-divider direction="vertical"></el-divider>
        </el-menu-item>
        <el-menu-item index="contact">联系我们</el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</div><!--/content-->
</div>

</el-affix>

 </el-header>
 <el-main class="main">
<div class="top-bg">
   <img class="wanyi" src="./assets/wanyi.svg" />
   <img class="year20" src="./assets/20year.svg" />
   <img class="player pointer" @click="playVideo('about')" src="./assets/player@2x.png" />
   <div class="f20 player-title">点击播放视频了解简约商业思维</div>
</div>
<div class="content kebg">
<h2 ref="ke">线上课程：认知商业商学课系列</h2>
<ul class="list">
  <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第一课：商业的本质－产品</li>
  <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第二课：商业的本质－效率与成本</li>
  <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第三课：商业的本质－传播</li>
  <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第四课：商业的本质－三项能力</li>
  <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第五课：企业的本质－战略、战术、战斗</li>
  <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第六课：企业的本质－战略与四项能力</li>
  <li class="pointer" @click="showQr('ke')"><i class="el-icon-caret-right"></i>第七课：企业的本质－战术与战斗</li>
  <li>...</li>
</ul>
<el-row class="btn-row">
<el-button type="warning" @click="showQr('ke')">立即学习</el-button><span>共20节课程</span>
</el-row>
</div><!--/kebg-->
<div class="xiliebg">
  <div class="content">
  <el-row type="flex" justify="space-between">
    <div class="xiliebox pointer" @click="showQr('contact')">
     <el-image
      style="width: 280px; height: 220px"
      :src="require('./assets/hz.png')"
      fit="cover"></el-image>
      <h3>线下公共课</h3>
      <div class="info">徐井宏老师线下与学员见面亲授，面向全国招募学员，不定期举办</div>
    </div><!--/xiliebox-->

    <div class="xiliebox pointer" @click="showQr('contact')">
     <el-image
      style="width: 280px; height: 220px"
      :src="require('./assets/sdh.png')"
      fit="cover"></el-image>
      <h3>简约私董会</h3>
      <div class="info">群体智慧聚焦为一个企业案主思考破局点,帮助企业找到本质问题，找到解决方案</div>
    </div><!--/xiliebox-->

    <div class="xiliebox pointer" @click="showQr('contact')">
     <el-image
      style="width: 280px; height: 220px"
      :src="require('./assets/xly.png')"
      fit="cover"></el-image>
      <h3>简约训练营</h3>
      <div class="info">理论加深，配合工具应用落地，小组互动深度社交，真正掌握知识的运用</div>
    </div><!--/xiliebox-->

    <div class="xiliebox pointer" @click="showQr('contact')">
     <el-image
      style="width: 280px; height: 220px"
      :src="require('./assets/manhe.png')"
      fit="cover"></el-image>
      <h3>简约盲盒系列</h3>
      <div class="info">工作坊+直播路演，企业家素养、商业计划书、商业模式创新三选一</div>
    </div><!--/xiliebox-->
  </el-row>
  </div><!--/content-->
</div><!--/xiliebg-->

<div class="content daoshi">
  <el-row type="flex" justify="space-between">
    <div class="left">
      <h2 ref="daoshi">导师团队介绍</h2>
      <el-row type="flex" class="bigds" justify="start">
        <div class="ds">
            <div class="avatar"></div>
            <h3>徐井宏</h3>
            <p>
              简约商业思维 创建者<br />
中关村龙门投资董事长<br />
亚杰商会荣誉会长<br />
中国企业家俱乐部副理事长
            </p>
        </div>
        <div class="info">
&nbsp;&nbsp;&nbsp;&nbsp;徐井宏，简约商业思维创建者，中关村龙门投资董事长，亚杰商会（AAMA）荣誉会长，中国企业家俱乐部（CEC）副理事长，清华企业家协会（TEEC）监事长等。曾担任亚太经合组织（APEC）中国工商理事会副主席、世界经济（达沃斯）论坛主席团（COC）成员等。<br /><br />
&nbsp;&nbsp;&nbsp;&nbsp;清华科技园的主要创建者和启迪控股的主要创始人，曾任清华控股董事长，期间带领清华控股跃升为2018年中国企业500强第137位，研发强度在中国企业500强中位居前三，旗下包括紫光、同方等科技集团，直接和间接控股10余家上市公司。所领导的投资机构投资有展讯通信、中文在线、兆易创新、海兰信、盛景网联等行业龙头企业。<br /><br />
&nbsp;&nbsp;&nbsp;&nbsp;其百亿规模的龙门基金，已投资奇安信、叮当快药等行业龙头企业。其担任会长的亚杰商会所发起的“摇篮计划”支持了包括美团、完美世界、猎聘网、流利说、拼多多创始人等数百名创业者。并出版有《转型》、《聚合》、《共赢》、《重构》等著作。<br />
        </div>
      </el-row>


      <el-row type="flex" class="bigds" justify="start">
        <div class="ds">
            <div class="avatar ztb"></div>
            <h3>朱天博</h3>
            <p>
简约商业思维 主理人<br />
中甬投创始合伙人、问榜创始人<br />
岂止书院创始发起人
            </p>
        </div>
        <div class="info">
&nbsp;&nbsp;&nbsp;&nbsp;朱天博，简约商业思维主理人、中甬投创始合伙人、岂止书院发起人、问榜创始人、台湾新人类文明文教基金会代表人。<br />
&nbsp;&nbsp;&nbsp;&nbsp;曾任新华社记者、宝宝树文化联合创始人，就读清华大学、中国传媒大学。社会职务有北京航空航天大学特聘教师、北京邮电大学创业导师、中国创翼创新创业大赛主评委等。<br />
&nbsp;&nbsp;&nbsp;&nbsp;主要投资案例：椰岛股份600238、每日优鲜、山姆大叔少儿英语、睿易教育835705、杨梅红艺术教育。顾问项目：东旭光电000413、脉脉、太极禅等。主要关注赛道: 科技教育、新消费、大健康。
        </div>
      </el-row>


    </div><!--/left-->
    <div class="right mt">
      <h2>媒体报道</h2>
      <el-image
        style="width: 356px; height: 200px" class="pointer"
        :src="require('./assets/mt-zcdsh.png')"
        fit="cover" @click="playqqVideo('zcdsh')"></el-image>
        <p  @click="playqqVideo('zcdsh')" class="pointer">总裁读书会：徐井宏老师分享《当下的力量》</p>
       <div class="space"></div>
      <el-image
        style="width: 356px; height: 200px"
        :src="require('./assets/mt-cxsj.png')" class="pointer"
        fit="cover"  @click="playqqVideo('cxsj')"></el-image>
        <p  @click="playqqVideo('cxsj')" class="pointer">财新视听采访徐井宏老师</p>

    </div><!--/right-->
  </el-row>

<el-row>

<h2>共创导师团队</h2>
<el-col :span="24">
    <el-row class="ds-list">
        <el-col :span="3">
        <div class="avatar tl"></div>
            <h3>檀林</h3>
            <p>
    简约商业思维共创人<br />
    海尔海创汇合伙人<br />前微软加速器CEO
            </p>
        </el-col>

        <el-col :span="3">
        <div class="avatar zzm"></div>
            <h3>张正明</h3>
            <p>
    简约商业思维共创人<br />
    海尔HOPE创新大使<br />
    创新顾问
            </p>
        </el-col>



        <el-col :span="3">
        <div class="avatar jy"></div>
            <h3>简一</h3>
            <p>
    简约商业思维共创人<br />
    岂止书院发起人<br />青林文化创始人
            </p>
        </el-col>


        <el-col :span="3">
        <div class="avatar lf"></div>
            <h3>罗飞</h3>
            <p>
    简约商业思维共创人<br />
    岂止书院发起人<br />问榜联合创始人
            </p>
        </el-col>


        <el-col :span="3">
        <div class="avatar hzt"></div>
            <h3>何战涛</h3>
            <p>
    简约商业思维共创人<br />
    东方快车联合创始人<br />传神语联联合创始人
            </p>
        </el-col>


        <el-col :span="3">
        <div class="avatar zxp"></div>
            <h3>周学平</h3>
            <p>
    简约商业思维共创教练<br />
    北斗领航创始人<br />人才辅导专家
            </p>
        </el-col>

        <el-col :span="3">
        <div class="avatar wq"></div>
            <h3>吴勤</h3>
            <p>
    简约商业思维共创教练<br />
    个人和组织效能提升顾问<br />高管教练
            </p>
        </el-col>




        <el-col :span="3">
        <div class="avatar yym"></div>
            <h3>杨越铭</h3>
            <p>
    简约商业思维共创编导<br />
    资深节目主编
            </p>
        </el-col>

    </el-row>
</el-col>

</el-row>

</div><!--/daoshi-->


<div class="fengcaibg">
  <div class="content">
    <h2 ref="jianfei">简粉风采</h2>
      <el-scrollbar height="590px">
       <el-row type="flex" justify="space-between">
        <el-image v-for="item in jianfeiData" :key="item.picUrl" :src="item.picUrl" class="pointer" @click="playVideo(item)" fit="cover" style="width:260px;height:260px;margin-bottom:50px;"></el-image>
       </el-row>
      </el-scrollbar>
  </div>
</div><!--/fengcaibg-->



<div class="contact">
  <div class="content">
    <h2 ref="contact">联系我们</h2>
    <el-row type="flex" justify="space-between">
      <div>
      <el-image
        style="width: 220px; height: 220px"
        :src="require('./assets/qr/new_mp.jpg')"
        fit="cover"></el-image>
        <p>关注简约商业思维公众号</p>
      </div>

      <div>
      <el-image
        style="width: 220px; height: 220px"
        :src="require('./assets/qr/new_qy_qun.jpg')"
        fit="cover"></el-image>
        <p>进入简约商业思维社群</p>
      </div>
      <div>
      <el-image
        style="width: 220px; height: 220px"
        :src="require('./assets/qr/jianyue.png')"
        fit="cover"></el-image>
        <p>关注简约商业思维视频号</p>
      </div>
      <div>
      <el-image
        style="width: 220px; height: 220px"
        :src="require('./assets/qr/xu.png')"
        fit="cover"></el-image>
        <p>关注徐井宏视频号</p>
      </div>
      <div>
      <el-image
        style="width: 220px; height: 220px"
        :src="require('./assets/qr/kefu.jpg')"
        fit="cover"></el-image>
        <p>添加客服顾问微信</p>
      </div>
    </el-row>
  </div>
</div><!--/contact-->
 </el-main>
 <el-footer height="200px">

<div class="footer-line"></div>
<div class="content">
<p>
<!--公司名称：-->
北京简约思维科技有限公司 
<!--
 /  公司地址：北京市海淀区中关村大街XXX  /  联系电话：40010000
 -->
</p>
<p>
Copyright 2020-{{(new Date()).getFullYear()}} © xminithink.com All rights reserved.  <a href="https://beian.miit.gov.cn/" style="color:#999;" target="_blank">京ICP备2022007505号</a>
</p>
</div>

 </el-footer>
</el-container>

<el-dialog
  v-model="videoDialog"
  :title="videoTitle"
  width="40%"
  destroy-on-close
  custom-class="video-dialog"
  >
<video style="width:100%;height:100%" autoplay controls controlsList="nodownload">
  <source :src="videoUrl" type="video/mp4">
Your browser does not support the video tag.
</video>
</el-dialog>




<el-dialog
  v-model="qqVideoDialog"
  :title="qqVideoTitle"
  width="80%"
  destroy-on-close
  custom-class="video-dialog"
  >
  <iframe style="width:100%;height:750px;" frameborder="0" :src="qqVideoUrl" allowFullScreen="true"></iframe>
</el-dialog>



<el-dialog
  v-model="qrDialog"
  :title="qrTitle"
  width="30%"
  destroy-on-close
  >
  <div class="qr-content">
  <img :src="qrUrl" style="width:90%" />
  </div>
</el-dialog>


</div><!--/index-->

</template>

<script>
import MixinData from './Mixin';
export default {
  name: 'Desktop',
  mixins:[MixinData]
}
</script>

<style scoped>
.pointer{
  cursor: pointer;
}
.f20{
  font-size:20px;
}


.loadingdiv{
  width: 300px;
  margin-top:100px;
}

.loadingdiv img{
  width:100%;
  margin-bottom:15px;
}


.content{
  max-width: 1200px;
  margin: 0 auto;
}
#index{
  /*
  background:url(/img/bg.png) center top;
  */
  background: #003483 center top;
  background-repeat: no-repeat;
  background-size: 2000px auto;
  height:600px;
}
.top-bg{
  height: 544px;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.main{
  padding:0!important;
  margin: 0!important;
}
:deep(.el-menu){
  background-color:transparent;
}
.el-menu.el-menu--horizontal{
  border-bottom:none!important;
}
.logo{
  width:235px;
  text-align: center;
  background: rgba(0, 0,0, 0.5);
  padding:10px;
  color:#76aad9;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}



.slogan{
  padding-top: 14px;
}

.logo img{
  width: 160px;
  height:36px;
}

.header-bg-color{
  height: 56px;
  background: rgba(0,0,0, 0.5)!important;
  transition: 0.5s;
}

.header-bg-color.darkbgcolor{
  background: rgba(0,0,0, 0.8)!important;
}

/deep/ .el-header{
  padding: 0px;
}

/deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus{
  color:#fff;
  background-color:transparent;
}

/deep/ .el-menu--horizontal > .el-menu-item.is-active{
  border-bottom:none;
  color: #e3a018!important;
}
/deep/ .el-menu--horizontal > .el-menu-item{
  color:#fff;
}
/deep/ .el-menu-item{
  height: 56px!important;
} 
.wanyi{
  width: 792px;
  height:99px;
}
.year20{
  width:565px;
  height:26px;
  margin-top:20px;
}
.player{
  width:122px;
  height:122px;
  margin-top:40px;
  margin-bottom: 20px;
}
.player-title{
  color:#00040d;
}
.kebg{
  padding-top: 60px;
  background: url('./assets/kebg@2x.png') no-repeat right top;
  background-size: 70%;
  background-position-y: 130px;
  min-height: 590px;
}
h2{
  font-size:30px;
  color:#101010;
  border-left:#e3a018 8px solid;
  padding-left: 20px;
}
.list {
   list-style: none;
   line-height: 250%;
}
.list li{
  color: #404040;
  font-size:24px;
}

.list li i{
  margin-right:10px;
  color:#9e9e9e;
}

.list li:last-child{
  padding-left: 35px;
}
.btn-row{
  padding-left: 48px;
}
.btn-row span{
font-size:20px;
color:#989898;
margin-top:5px;
margin-left:30px;
}
.xiliebg{
 background: #edf7ff;
 padding: 60px 0;
}

.xiliebg .xiliebox{
  background: #fff;
  width: 280px;
  border-bottom: 2px solid #e8cb8b;
}
.xiliebg h3{
  font-size:20px;
  color:#404040;
  margin-top:15px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.xiliebg .info{
  color:#989898;
  font-size:14px;
  padding:0 15px 20px 15px;
}

.daoshi{
  padding: 40px 0;
}

.daoshi .left{
  width:770px;
}

.daoshi .right{
  width:354px;
}
.daoshi .bigds{
  padding-top:20px;
  padding-bottom:20px;
}
.daoshi .bigds .ds{
  width:230px;
  text-align: center;
}

.daoshi .bigds .ds h3{
  font-size:24px;
  color:#101010;
  margin:10px 0;
}
.daoshi .bigds .ds p{
  font-size:14px;
  color:#898989;
}

.daoshi .bigds .info{
  width:540px;
  color:#404040;
  font-size:14px;
  line-height: 180%;
}

.mt p{
  color:#404040;
  font-size:16px;
}
.mt .space{
  height:30px;
}
.contact{
  background: #f9f9f9;
  padding: 30px 0;
}
.contact p{
  text-align: center;
  font-size:18px;
  color:#888888;
}
/deep/ .el-footer{
  padding:0;
  background: #f9f9f9;
}
.footer-line{
  height: 0;
  border-top:1px solid #dfdfdf;
  border-bottom:1px solid #ffffff;
  margin-bottom: 40px;
}
.el-footer p{
  font-size:24px;
  color:#404040;
  text-align: center;
}

.el-footer p:last-child{
  font-size:16px;
  color:#999999;
}
/deep/ .video-dialog .el-dialog__body{
  padding-top:0!important;
}

/deep/  .qr-content{
  text-align: center;
  padding:0 30px 30px 30px;
}
/deep/ .el-menu-item .el-divider--vertical{
  position: relative;
  left:19px;
  top:-2px;
}

.fengcaibg{
  background: url('./assets/jianfeibg@2x.png');
  height:760px;
  background-size:cover;
  padding:30px 0;
}

.fengcaibg h2{
  color:#fff;
  margin-bottom: 50px;
}

.ds-list h3{
    text-align: center;
    margin-block-start:0;
    margin-block-end:0;
    margin-top:10px;
}

.ds-list p{
    text-align: center;
    margin-block-start:0;
    margin-block-end:0;
    font-size:9px;
    margin-top:5px;
    color:#898989;
}

.avatar{
    background: url('/img/ds-avatar.png') no-repeat center top;
    background-size:120px auto;
    width: 120px;
    height:126px;
    margin:0 auto;
}

.avatar.ztb{
    background-position-y: -127px;
}

.avatar.zzm{
    background-position-y: -255px;
}

.avatar.tl{
    background-position-y: -384px;
}

.avatar.jy{
    background-position-y: -516px;
}

.avatar.lf{
    background-position-y: -647px;
}

.avatar.hzt{
    background-position-y: -779px;
}

.avatar.zxp{
    background-position-y: -911px;
}

.avatar.wq{
    background-position-y: -1044px;
}
.avatar.yym{
    background-position-y: -1175px;
}

/deep/ .el-menu-item{
  border-bottom-color: transparent!important;
}


</style>
