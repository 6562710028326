import { easeInOutCubic } from 'element-plus/lib/utils/animation'
let MixinData ={
    data(){
        return {
          darkbgcolor:false,
          activeIndex:"home",
          videoDialog:false,
          bgisloaded:false,
          videoUrl:"",
          videoTitle:"",
          //腾讯视频播放窗口， 带广告， 主要是媒体报道的视频
          qqVideoDialog:false,
          qqVideoUrl:"",
          qqVideoTitle:"",
          qqVideoData:{
            "zcdsh":["【总裁读书会】徐井宏领读《当下的力量》","https://v.qq.com/txp/iframe/player.html?vid=r3245qgsay3"],
            "cxsj":["【财新时间】徐井宏：狭义创业最终只属于少数人","https://v.qq.com/txp/iframe/player.html?vid=q3238yunqum"]
          },
          qrDialog:false,
          show_loading:false,
          loadper:0,
          qrUrl:"",
          qrTitle:"",
          qrData:{
            "ke":["扫描开始学习",require("./assets/qr/mp.jpg")],
            "contact":["请添加简一微信进行报名咨询",require("./assets/qr/qiye_qr.png")]
          },
          videoData:{
            "about":["快速了解简约商业思维",'https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/a556b722387702301427900215/v.f100030.mp4']
          },
          jianfeiData:[
            {"title":"冯新|碳9资本创始人","picUrl":require("./assets/jianfei/1.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e4a5c231387702301411662898/v.f100030.mp4"},
            {"title":"何战涛|简约商业思维共创人","picUrl":require("./assets/jianfei/2.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e6d60fa8387702301411741198/v.f100030.mp4"},
            {"title":"孙宏光|大圣悦读","picUrl":require("./assets/jianfei/3.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/ebcb0934387702301411985659/v.f100030.mp4"},
            {"title":"孙国化|广东梦峰科技创始人","picUrl":require("./assets/jianfei/4.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/ebcaf7eb387702301411985236/v.f100030.mp4"},
            {"title":"Anna|VIIVA全球合伙人","picUrl":require("./assets/jianfei/5.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e92c61cb387702301411843576/v.f100030.mp4"},
            {"title":"金文俊|上海天拓电气有限公司","picUrl":require("./assets/jianfei/6.jpg"),"videoUrl":"wu"},
            {"title":"史红领|北京智仝咨询创始人","picUrl":require("./assets/jianfei/7.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e6facbde387702301411762104/v.f100030.mp4"},
            {"title":"宋振|安诚国际保安CEO","picUrl":require("./assets/jianfei/8.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/ebca8170387702301411984782/v.f100030.mp4"},
            {"title":"那春辉|应在路上学校创始人","picUrl":require("./assets/jianfei/9.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e98767cd387702301411894509/v.f100030.mp4"},
            {"title":"简一|简约商业思维共创人","picUrl":require("./assets/jianfei/10.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e6ec0265387702301411758241/v.f100030.mp4"},
            {"title":"刘嘉|岂止书院发起人","picUrl":require("./assets/jianfei/11.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e91b61a4387702301411835541/v.f100030.mp4"},
            {"title":"康利霞|北京律师","picUrl":require("./assets/jianfei/12.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e6ec9b46387702301411759520/v.f100030.mp4"},
            {"title":"将启迪|念·美学工作室联合创始人","picUrl":require("./assets/jianfei/13.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e9522648387702301411866127/v.f100030.mp4"},
            {"title":"任威|学音悦网校联合创始人CEO","picUrl":require("./assets/jianfei/14.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e6fa5527387702301411761636/v.f100030.mp4"},
            {"title":"罗飞|海天地科技集团CTO","picUrl":require("./assets/jianfei/15.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e91b734e387702301411835992/v.f100030.mp4"},
            {"title":"DK|宠爱联萌社群创始人","picUrl":require("./assets/jianfei/16.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/eb3896dc387702301411902403/v.f100030.mp4"},
            {"title":"晓辉|动脉社群发起人","picUrl":require("./assets/jianfei/17.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e9620969387702301411872059/v.f100030.mp4"},
            {"title":"张金龙|南京君发科技创始人","picUrl":require("./assets/jianfei/18.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/23b43beb387702301412074858/v.f100030.mp4"},
            {"title":"岳鹏|创业之心发起人","picUrl":require("./assets/jianfei/19.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/ebcd2171387702301411989076/v.f100030.mp4"},
            {"title":"旭霞|终身学习实践者","picUrl":require("./assets/jianfei/20.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e9509b49387702301411863770/v.f100030.mp4"},
            {"title":"许德保|平凡人","picUrl":require("./assets/jianfei/21.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e92acd68387702301411840954/v.f100030.mp4"},
            {"title":"顾创伟|中国公租房互联网+领跑者","picUrl":require("./assets/jianfei/22.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e6eaf2ae387702301411756486/v.f100030.mp4"},
            {"title":"王磊|若尘埃情感平台创始人","picUrl":require("./assets/jianfei/23.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e91da3cd387702301411839915/v.f100030.mp4"},
            {"title":"曹文芳|云颐航空创始人","picUrl":require("./assets/jianfei/24.jpg"),"videoUrl":"https://1254233526.vod2.myqcloud.com/bc207c4bvodtranscq1254233526/e6c7c986387702301411738091/v.f100030.mp4"},
          ]
        }
      },
      components: {
      },
      mounted(){
        window.addEventListener("scroll", this.handleScroll);
        this.show_loading=false;
        this.bgisloaded=true;
        this.loadImage('/img/bg.png');
        /*
        setTimeout(()=>{
          if(this.loadper<80){
            //1秒钟后，加载资源小于80，显示加载中
            this.show_loading=true;
          }
        },1000);
        */
      },
      unmounted() {
        //离开该页面需要移除这个监听的事件
        window.removeEventListener("scroll", this.handleScroll);
      },
      methods: {
        //total是图片的总张数。
        loadImage(url){
            let xmlHTTP = new XMLHttpRequest();
            xmlHTTP.open('GET', url,true);
            xmlHTTP.responseType = 'arraybuffer';
            let last_completed=0;
            xmlHTTP.onprogress = (e) => {
                let completedPercentage = parseInt((e.loaded / e.total) * 100);
                let per=completedPercentage-last_completed;
                //这里用一个global_loadper 是因为this.loaderper 要等到刷新时间才会变化
                this.loadper+=parseInt(per);
                if(this.loadper>=99){
                  this.bgisloaded=true;
                }
                last_completed=completedPercentage;
            };

            xmlHTTP.onload = () => {
              var base64, binary, bytes, mediaType;
       
              bytes = new Uint8Array(xmlHTTP.response);
              //NOTE String.fromCharCode.apply(String, ...
              //may cause "Maximum call stack size exceeded"
              binary = [].map.call(bytes, function (byte) {
                  return String.fromCharCode(byte);
              }).join('');
              mediaType = xmlHTTP.getResponseHeader('content-type');
              base64 = [
                  'data:',
                  mediaType ? mediaType + ';':'',
                  'base64,',
                  btoa(binary)
              ].join('');
              this.$refs['indexbg'].style.backgroundImage="url('"+base64+"')"
          };

            xmlHTTP.send();

        }
        ,
        playVideo(type){
          if(this.videoData[type]){
            this.videoTitle=this.videoData[type][0];
            this.videoUrl=this.videoData[type][1];
            this.videoDialog=true;
          }else if('object'==typeof(type)){
            this.videoTitle=type.title;
            this.videoUrl=type.videoUrl;
            this.videoDialog=true;
          }else{
            console.error("视频类型错误");
          }
        },
        playqqVideo(type){
          if(this.qqVideoData[type]){
            this.qqVideoTitle=this.qqVideoData[type][0];
            this.qqVideoUrl=this.qqVideoData[type][1];
            this.qqVideoDialog=true;
          }else{
            console.error("视频类型错误");
          }
        },
        showQr(type){
          if(this.qrData[type]){
            this.qrTitle=this.qrData[type][0];
            this.qrUrl=this.qrData[type][1];
            this.qrDialog=true;
          }else{
            console.error("二维码类型错误");
          }
        },
        getScrollHeight(){
            let scrollHeight = 0,bSH=0,dSH=0;
            if(document.body){
            bSH = document.body.scrollHeight;
            }
            if(document.documentElement){
            dSH = document.documentElement.scrollHeight;
            }
            scrollHeight = (bSH - dSH > 0) ? bSH : dSH ;
            return scrollHeight;
            },
        getWindowHeight(){
              let windowHeight = 0;
              if(document.compatMode == "CSS1Compat"){
                windowHeight = document.documentElement.clientHeight;
              }else{
                windowHeight = document.body.clientHeight;
              }
              return windowHeight;
        },
        handleScroll() {
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          let menus=['ke','daoshi','jianfei','contact'];
          let select_menu="home";
          for(const m of menus){
            let obj_offset=this.getOffsetTop(this.$refs[m]);
            if(!obj_offset){
              continue;
            }
            if(scrollTop >= obj_offset-120 ){
              select_menu=m;
            }
          }
          if(scrollTop+this.getWindowHeight()>this.getScrollHeight()-50){
            select_menu='contact';
          }
          //如果超出了首页，颜色变深
          if(select_menu!='home'){
            this.darkbgcolor=true;
          }else{
            this.darkbgcolor=false;
          }
          this.activeIndex=select_menu;
        },
        tabClick(key) {
          let scrollTop = this.getOffsetTop(this.$refs[key]);
          //120是头部的高度
          if(scrollTop>120){
            scrollTop-=120;
          }
          const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16))
          const beginTime = Date.now();
          const beginValue=window.scrollY;
          const frameFunc = () => {
            const progress = (Date.now() - beginTime) / 500
            if (progress < 1) {
              let topValue = scrollTop-(scrollTop-beginValue) * (1 - easeInOutCubic(progress)) 
              window.scrollTo({
                    top: topValue
              });
              rAF(frameFunc)
            } else {
              window.scrollTo({
                    top: scrollTop
              });
            }
          }
          rAF(frameFunc);
        },
        getOffsetTop(obj) {
             let offsetTop = 0;
             while (obj != window.document.body && obj != null) {
              offsetTop += obj.offsetTop;
              obj = obj.offsetParent;
              }
              return offsetTop;
        },
        bgloaded(){
          this.bgisloaded=true;
        }
    
      }//end methods
};
export default MixinData;