<template>
   <Mobile v-if="mobile"></Mobile>
   <Desktop v-if="!mobile"></Desktop>
</template>

<script>
import Mobile from './Mobile'
import Desktop from './Desktop'
export default {
  name: 'App',
  data(){
    const is_mobile=document.documentElement.clientWidth<1000;
    return {
      mobile:is_mobile
    }
  },
  components: {
    Mobile,
    Desktop
  }
}
</script>

